<template>
  <div id="app" class="url" style="padding: 15px 0;backgroundColor:white">
    <div>
      <el-row>
        <div>
          <el-col :span="6" class="rows">
            <el-input placeholder="请输入设备id" v-model="deviceId"></el-input>
          </el-col>
          <el-col :span="6" class="rows">
            <el-cascader placeholder="请选择单位" :show-all-levels="false" clearable ref="cascader1" :options="allCompanyList" @change="checkedCompany" :props="defaultProps">
            </el-cascader>
          </el-col>
          <el-col :span="6" class="rows">
            <el-date-picker v-model="dataTime" popper-class="pickerclass" type="datetimerange" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss" :clearable="false" :picker-options="pickerOptions">
            </el-date-picker>
          </el-col>
          <el-col :span="6" class="rows">
            <div>
              <el-radio-group v-model="dataType">
                <el-radio label="1" style="margin-right:4px" border>日用水量</el-radio>
                <el-radio label="2" border>月用水量</el-radio>
              </el-radio-group>
            </div>
          </el-col>
          <el-button type="primary" @click="getWaterMeterData(1)">查询</el-button>
        </div>
      </el-row>
      <div>
        <el-dialog :visible.sync="dialogVisible" width="50%" @open="openDialog()">
          <div id="main" ref="chart"></div>
        </el-dialog>
      </div>
      <el-row style="margin-top:50px">
        <div>
          <el-table :data="tableData" border style="width: 100%;">
            <el-table-column fixed prop="id" label="序号" width="65px">
            </el-table-column>
            <el-table-column fixed prop="deviceId" label="设备ID" width="150px">
            </el-table-column>
            <el-table-column fixed prop="deviceName" label="设备名称" width="150px">
            </el-table-column>
            <el-table-column fixed prop="sumTotal" label="总用水" width="80px">
            </el-table-column>
            <el-table-column fixed prop="averageTotal" label="平均用水" width="80px">
            </el-table-column>
            <el-table-column fixed prop="leakage" label="漏失率" width="80px">
            </el-table-column>
            <el-table-column v-for="(item, index) in showTime" :key="index" :prop="item" :label="item">
            </el-table-column>
            <el-table-column fixed="right" label="操作" width="75px">
              <template slot-scope="scope">
                <el-button type="text" size="small" @click="showEcharts(scope.row)">查看</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-row>
      <el-pagination background layout="total, prev, pager, next, jumper" @current-change="handleCurrentChange" :current-page.sync="current" :page-size="limit" :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
var echarts = require("echarts");
import request from '../../../utils/request'
import companySystem from "@/api/managementApi/CompanyInfo";
export default {
  data() {
    return {
      data: {
        title: {
          text: '用水量(L)'
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: []
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
          iconStyle: {
            color: "white",
          },
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: []
        },
        yAxis: {
          type: 'value'
        },
        series: [
        ]
      },
      pickerOptions: {//时间选择
        onPick: ({ maxDate, minDate }) => {
          this.selectDate = minDate.getTime();
          if (maxDate) {
            this.selectDate = ''
          }
        },
        disabledDate: (time) => {
          //如果是日用水量,只能选择一个月的
          if (this.dataType == 1) {
            if (this.selectDate !== '') {
              const one = 30 * 24 * 3600 * 1000;
              const minTime = this.selectDate - one;
              const maxTime = this.selectDate + one;
              return time.getTime() < minTime || time.getTime() > maxTime
            }
          }
        }
      },
      dataTime: [new Date(new Date(new Date().toLocaleDateString()).getTime() - 1000 * 3600 * 24 * 7), new Date(new Date(new Date().toLocaleDateString()).getTime())],//选择的时间
      showTime: [],//展示在页面的每一天日期集合
      dataType: "1",//Daily(1)是日用水量,Monthly(2)是月用水量
      startTime: "",//数据查询起始时间
      endTime: "",//数据查询终止时间
      deviceId: "",//查询的设备id
      ciId: "",//查询的公司id
      allCompanyList: [],//公司结构集合
      defaultProps: {
        children: "smallCompanyList", // 子公司节点
        label: "name", // 公司名
        value: "id",
        checkStrictly: true,
      },//公司信息
      companyName: "",//选择的公司名称
      tableData: [],//表格数据
      current: 1,//当前页
      limit: 10,//显示条数
      total: 0,//数据总数
      dialogVisible: false,//是否显示弹出框
    };
  },
  created() {
  },
  mounted() {
    this.getAllCompanyList()//获取公司结构
    this.showTime = this.getWeekTime(this.formatDateTime(this.dataTime[0], false), this.formatDateTime(this.dataTime[1], false), false)//获取当前时间往前一星期时间
    // this.data.xAxis.data = this.showTime
    // this.createEcharts(this.data)//生成echarts图
    // this.deviceId = "861821064871822"
    // this.ciId = "1_5"
    this.getWaterMeterData()//获取数据
  },
  methods: {
    //获取设备用水量数据
    getWaterMeterData(current) {
      if (current != undefined && current != null && current != "") {
        this.current = current
      }
      let obj = {}
      obj.current = this.current
      obj.limit = this.limit
      if (this.dataType == "1") {
        obj.type = "Daily"
      } else {
        obj.type = "Monthly"
      }
      //选择了一段时间
      if (this.dataTime != null && this.dataTime != "" && (this.dataTime[0] != undefined && this.dataTime[1] != undefined)) {
        obj.startTime = this.formatDateTime(this.dataTime[0], true)
        obj.endTime = this.formatDateTime(this.dataTime[1], true)
        if (this.dataType == "1") {
          let time = this.getWeekTime(this.formatDateTime(this.dataTime[0], false), this.formatDateTime(this.dataTime[1], false), false)
          if (time.length > 31) {
            this.$message({
              message: "最大查询一个月日用水",
              type: "warning"
            })
            return;
          } else {
            this.showTime = time
          }
        } else {
          this.showTime = this.getWeekTime(this.formatDateTime(this.dataTime[0], false), this.formatDateTime(this.dataTime[1], false), true)
        }
      }
      if (this.deviceId != "") {
        obj.deviceId = this.deviceId
      }
      if (this.ciId != "") {
        obj.ciId = this.ciId
      }
      request({ url: `/device-history-value/getWaterMeterData`, method: 'post', data: obj }).then(res => {
        this.total = res.data.data.total
        let data = res.data.data.list
        let arr = []
        for (let i = 0; i < data.length; i++) {
          const element = data[i];
          let obj = {}
          let timeList = element.timeList
          let valueList = element.valueList
          obj.id = Number(i + 1)
          obj.deviceId = element.deviceId
          obj.deviceName = element.deviceName
          obj.timeList = timeList
          obj.valueList = valueList
          for (let i = 0; i < this.showTime.length; i++) {
            const element = this.showTime[i];
            obj[element] = "无"
          }
          //获取总用水和平均用水
          let sumTotal = Number(0)
          for (let i = 0; i < timeList.length; i++) {
            const element = timeList[i];
            obj[element] = valueList[i]
            sumTotal += Number(valueList[i])
          }
          if (timeList.length != 0) {
            obj.sumTotal = sumTotal.toFixed(2)
            obj.averageTotal = (Number(sumTotal) / Number(timeList.length)).toFixed(2)
          } else {
            obj.sumTotal = Number(0).toFixed(2)
            obj.averageTotal = Number(0).toFixed(2)
          }
          //如果有漏失率相关数据
          obj.leakage = "无"
          let sonSumTotal = Number(0)//子设备总用水
          if (element.leakage != undefined) {
            for (const key in element.leakage) {
              const element1 = element.leakage[key];
              for (let j = 0; j < element1[0]["valueList"].length; j++) {
                sonSumTotal += Number(element1[0]["valueList"][j])
              }
            }
            if (Number(sumTotal.toFixed(2)) > Number(sonSumTotal.toFixed(2))) {
              obj.leakage = ((sumTotal.toFixed(2) - sonSumTotal.toFixed(2)) / sumTotal.toFixed(2) * 100).toFixed(2) + "%"
            }
          }
          arr.push(obj)
        }
        this.tableData = arr
      })
    },
    //打开弹出框
    openDialog() {
      setTimeout(() => {
        this.createEcharts(this.data)
      }, 0)
    },
    //生成echarts图表
    createEcharts(data) {
      let myChart = echarts.init(this.$refs.chart)
      myChart.setOption(data);
      window.onresize = myChart.resize
    },
    //展示echarts图表
    showEcharts(data) {
      //echarts图数据处理
      let obj = {}
      obj.type = "line"
      obj.stack = "Total"
      obj.name = data.deviceId
      let array = []
      array.push(data.deviceId)
      this.data.legend.data = array
      obj.data = data.valueList
      this.data.xAxis.data = data.timeList
      let arr = []
      arr.push(obj)
      this.data.series = arr
      if (data.timeList.length == 0) {
        this.$message({
          message: "无数据",
          type: "warning"
        })
      } else {
        this.dialogVisible = true
      }
    },
    //获取指定时间段内的每一天
    getWeekTime(begin, end, isMonth) {
      var arr = [];
      var ab = begin.split("-");
      var ae = end.split("-");
      var db = new Date();
      db.setUTCFullYear(ab[0], ab[1] - 1, ab[2]);
      var de = new Date();
      de.setUTCFullYear(ae[0], ae[1] - 1, ae[2]);
      var unixDb = db.getTime() - 24 * 60 * 60 * 1000;
      var unixDe = de.getTime() - 24 * 60 * 60 * 1000;
      if (isMonth) {
        let set = new Set()
        for (var k = unixDb; k <= unixDe;) {
          k = k + 24 * 60 * 60 * 1000;
          set.add((new Date(parseInt(k))).Format('yyyy-MM'));
        }
        arr = Array.from(set)
      } else {
        for (var k = unixDb; k <= unixDe;) {
          k = k + 24 * 60 * 60 * 1000;
          arr.push((new Date(parseInt(k))).Format('yyyy-MM-dd'));
        }
      }
      return arr;
    },
    //修改时间格式
    formatDateTime(inputTime, isShow) {
      var date = new Date(inputTime);
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      var h = date.getHours();
      h = h < 10 ? "0" + h : h;
      var minute = date.getMinutes();
      var second = date.getSeconds();
      minute = minute < 10 ? "0" + minute : minute;
      second = second < 10 ? "0" + second : second;
      if (isShow) {
        return y + "-" + m + "-" + d + " " + h + ":" + minute + ":" + second;
      } else {
        return y + "-" + m + "-" + d;
      }
    },
    //点击页数
    handleCurrentChange(row) {
      this.current = row
      this.getWaterMeterData()
    },
    // 查询公司结构
    getAllCompanyList() {
      companySystem.getAllCompanyStructure().then((res) => {
        // 公司结构集合
        this.allCompanyList = res.data.data.list;
      });
    },
    //选择公司触发方法
    checkedCompany() {
      if (this.$refs.cascader1.getCheckedNodes()[0] == undefined) {
        this.ciId = '';
      } else {
        this.ciId = this.$refs.cascader1.getCheckedNodes()[0].data.id;
      }
      this.$refs.cascader1.dropDownVisible = false;
    },
    // 返回上一级
    goBack() {
      this.$router.push("/Select");
    },
  },
};
</script>
<style>
.pickerclass
  .el-picker-panel__footer
  .el-picker-panel__link-btn.el-button--text {
  display: none;
}
</style>
<style scoped>
#main {
  width: 100%;
  height: 380px;
  box-sizing: border-box;
}
.rows {
  margin: 0px 6px;
}
.el-pagination {
  text-align: center;
  position: absolute;
  width: 100%;
  bottom: 5px;
}
</style>
